import { useState, useEffect } from "react";
import { useAuth } from "../../hooks/AuthProvider";
import moment from 'moment';

export default function Portfolio() {
  const [result, setResult] = useState({
    "total_subs": 0,
    "total_assets": 0,
    "total_margin": 0,
    "total_percent": 0,
  }); 
  const [funds, setFunds] = useState([]);
  const [fullname, setFullname] = useState("-");
  const [phone, setPhone] = useState("-");
  useEffect(() => {
    fetchData()
      .then((result) => {
        setResult(result);
        setFunds(result.funds);
        setFullname(localStorage.getItem("fullname"));
        setPhone(localStorage.getItem("phone"));
      })
      .catch(() => {
        console.log('Error occured when fetching books');
      });
  },[]);
  const fetchData = async () => {
    console.log(localStorage.getItem("access_token"));
    const response = await fetch(`${process.env.REACT_APP_API_URL}/customerportfolio`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("access_token")}`
      },
    });
    const res = await response.json();
    return res.result
  }
  const auth = useAuth();
  return (<div className="mb-20 container mx-auto max-w-screen-xl">
    <div className="w-full text-right">
      <button onClick={() => auth.logOutAction(localStorage.getItem("access_token"))} 
        className="text-white bg-orange-700 border border-gray-200 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Exit</button>
    </div>
    <p className="font-medium text-xl my-3 text-white">Client Info</p>
    <div className="mb-6 p-6 bg-gray-100/10 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  text-white">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
        <div>
          <div className="grid grid-cols-1 divide-y gap-4">
            <div>
              <span className="font-medium">Nama Lengkap</span><br/>
              <span className="font-extralight text-md">{fullname}</span>
            </div>
            <div>
              <br/><span className="font-medium">No. Handphone</span><br/>
              <span className="font-extralight text-md">{phone}</span>
            </div>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-1 divide-y gap-4">
            <div>
              <span className="font-medium">Total Investasi</span><br/>
              <span className="font-extralight text-md">{currencyFormat(result.total_assets)}</span>
            </div>
            <div>
              <br/><span className="font-medium">Total Imbal Hasil</span><br/>
              <div className="grid grid-cols-4 gap-4 content-end">
                <span className="font-extralight text-md col-span-3">{currencyFormat(result.total_margin)}</span>
                {result.total_percent >= 0
                  ?<span className="rounded-full p-1 bg-gray-100 text-green-600 font-medium text-center">&#9650;&nbsp;{result.total_percent}%</span>
                  :<span className="rounded-full p-1 bg-red-100 text-green-600 font-medium text-center">&#9660;&nbsp;{result.total_percent}%</span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p className="font-medium text-xl mb-3 text-white">Reksadana Anda</p>
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {funds.map((c, index) => (  
        <div key={index} className="p-6 bg-white text-gray-900 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <div className="mb-3">
              <img className="float-left mr-3" src={`https://fund.alphainvestasi.id/uploads/products/icons/${c.fund_img}`} alt={c.fund_id}/>
              <h5 style={{color: "#1F2937"}} className="mb-2 text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-900">{c.fund_name}</h5>
          </div>
          <hr/>
          <div className="grid grid-cols-2 gap-4 text-md">
            <div className="grid grid-cols-1 gap-4">
              <div>
                <span className="font-semibold">Jumlah Unit</span><br/>
                <span className="font-extralight">{unitFormat(c.unit_volume)}</span>
              </div>
              <div>
                <span className="font-semibold">Nilai Semula</span><br/>
                <span className="font-extralight">{currencyFormat(c.asset-c.margin)}</span>
              </div>
              <div>
                <span className="font-semibold">Nilai Saat Ini</span><br/>
                <span className="font-extralight">{currencyFormat(c.asset)}</span>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <span className="font-semibold">Harga Per Unit({moment().format("DD/MM/YYYY")})</span><br/>
                <span className="font-extralight">{currencyFormat(c.nav_per_unit)}</span>
              </div>
              <div>
                <span className="font-semibold">Harga Per Unit(Rata-rata)</span><br/>
                <span className="font-extralight">{currencyFormat((c.asset-c.margin)/c.unit_volume)}</span>
              </div>
              <div className="text-green-600">
                <span className="font-semibold">Imbal Hasil</span><br/>
                <span className="font-extralight">{currencyFormat(c.margin)}</span>
              </div>
            </div>
          </div>
        </div>
      ))} 
      </div>
  </div>);
  function unitFormat(num) {
    return Math.round(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  function currencyFormat(num) {
    return 'Rp. ' + Math.round(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${month}/${date}/${year}`;
  }
}